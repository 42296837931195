$offsets: 1, 2, 3, 4, 5;

@mixin offset-mixin($pretx:"", $postx:"") {
    @each $offset in $offsets {
        &.left-#{$offset} {
            transform: #{$pretx} translateX($offset * -1px) #{$postx};
        }
        &.right-#{$offset} {
            transform: #{$pretx} translateX($offset * 1px) #{$postx};
        }
        &.top-#{$offset} {
            transform: #{$pretx} translateY($offset * -1px) #{$postx};
        }
        &.bottom-#{$offset} {
            transform: #{$pretx} translateY($offset * 1px) #{$postx};
        }
    }
    margin: 0;
    padding: 0;
    list-style: none;
}

.rot-180{
    @include offset-mixin("", rotate(180deg))
}

.left-2{
    transform: tr
}
