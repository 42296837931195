.content-mode-editor {
    .main {
        > .container-fluid {
            padding-bottom: 0 !important;
            > div > div.configuration-component {
                padding-top: 7px;

                .row.content-title {
                    margin-bottom: .25rem !important;
                }

                .card.content-card > .card-body{
                    padding: 0.5rem;

                    .row.actions {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}