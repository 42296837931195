
.gui-base {
    position: relative;

    > .gui-3dview {
        position: absolute;
        top: 5px;
        left: 5px;

        .toolbar {
            display: flex;

            gap: 12px;

            &.is-vertical {
                width: 36px;
                flex-direction: column;
            }

            .btn {
                &.btn-clear {
                    color: #ffffff;
                }
                :not(:hover):not(:focus) {
                    opacity: .5;
                    &::after {
                        opacity: .5;
                    }
                }
            }
        }
    }

}
