

.notification-box {
    min-width: 300px;

    &.floating {
        position: absolute;
        z-index: 1000;

        &.top {
            top: 0;
        }

        &.right {
            right: 0;
        }

    }
    
}