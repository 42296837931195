.range-slider {
    position: relative;
    display: inline-block;
    margin: 5px 0;

    &.has-labels {
        height: 2.5em;
    }

    > .label {
        position: absolute;
        bottom: 0;
        // background-color: red;

        &.clickable{
            cursor: pointer;
            user-select: none;
        }


        &.left{
            left: 0;
        }

        &.right{
            right: 0;
        }
    }
}