// Here you can add other styles

.container-fluid {
    min-height: 100%;
    position: relative;
    padding-bottom: 70px !important;
    @media (max-width: 500px) {
        padding: 0 20px !important;
        padding-bottom: 70px !important;
    }
}

.DateRangePicker_picker {
    z-index: 999 !important;
}

.card {
    z-index: 1;
}

.clickable {
    cursor: pointer;
}


.tab-content.no-padding {
    > .tab-pane {
        padding: 0;
    }
}

.list-group-h12 {
    max-height: 24em;
    overflow-y: auto;
}

//Body
.animated.fadeIn {
    z-index: 1;
    position: relative;
    padding-top: 20px;
    @media (max-width: 450px) {
        padding-top: 10px;
    }
}
.app-body {
    font-family: 'Libre Franklin';
}

.app-body .main {
    background: #fafbfd;
}

.background-overlay {
    position: fixed;
    right: 0px;
    bottom: 0px;
    z-index: 0;
    pointer-events: none;
    @media (max-width: 700px) {
        margin-right: -14px;
    }
}

.footer-left {
    position: absolute;
    bottom: 6%;
    margin-left: 30px;
    z-index: 0;
    font-size: 13px;
    font-family: 'Libre Franklin';
    color: #195590;
    opacity: 0.8;
    @media (max-width: 700px) {
        bottom: 40px;
        font-size: 12px;
        margin-left: 2px;
    }
}

//Header
.app-header.navbar {
    background: #ffffff;
}

.ml-auto.navbar-nav {
    padding-right: 15px;
}

.d-md-down-none.navbar-toggler {
    border: 2px solid #f1f1f1;
    transform: scale(0.7);
}

.navbar-toggler-icon {
    opacity: 0.5;
}

@media (max-width: 991.98px) {
    .app-header .navbar-brand {
        position: static;
    }
}

//Side-bar
.sidebar {
    background: #eef8ff;
    z-index: 3 !important;
    width: 210px !important;
}

.sidebar .nav .nav-item {
    background: #1d78db;
}

.sidebar .nav {
    padding-top: 35%;
    width: 220px;
    .nav-link {
        color: #777777;
        background: #eef8ff;
        padding-left: 4px;
        transition: padding-left 0.5s, margin-left 0.5s, padding-right 0.5s;

        &:hover {
            color: #1d78db;
            .nav-icon {
                color: inherit;
            }
        }

        &.active {
            background: #eef8ff;
            color: #1d78db;
            margin-left: 4px;
            padding-left: 6px;
            padding-right: 10px;
        }
    }
}

//Location card
.location-card {
    border: 1px solid #f1f1f1;
    min-width: 460px;
    border-radius: 10px;
    height: 186px;
    -webkit-box-shadow: 0px 3x 0px 0px rgba(237, 237, 237, 1);
    -moz-box-shadow: 0px 3px 0px 0px rgba(237, 237, 237, 1);
    box-shadow: 0px 3px 0px 0px rgba(237, 237, 237, 1);
}

.location-card__header {
    margin: 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 2px dotted #9b9b9b;

    .location-card__title {
        color: #1d78db;
        font-size: 25px;
    }
    a {
        color: #1d78db;
    }

    h5 {
        color: #888889;
        font-size: 17px;
    }
}

.location-card__content {
    margin: 20px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.location-card__stat {
    margin: 0;
    display: flex;
    justify-content: space-around;
    align-items: top;

    .location-card__stat__icon--green {
        background-color: #00b24c;
        border-radius: 50%;
        width: 45px;
        height: 45px;
        display: flex;
        margin-right: 0.8em;
        margin-top: 4px;
    }

    .location-card__stat__icon--yellow {
        background-color: #d3c833;
        border-radius: 50%;
        width: 45px;
        height: 45px;
        display: flex;
        margin-right: 0.8em;
        margin-top: 4px;
    }
    
    .location-card__stat__icon--red {
        background-color: #d83f1e;
        border-radius: 50%;
        width: 45px;
        height: 45px;
        display: flex;
        margin-right: 0.8em;
        margin-top: 4px;
    }

    .location-card__stat__icon--blue {
        background-color: #11599c;
        border-radius: 50%;
        width: 45px;
        height: 45px;
        display: flex;
        margin-right: 0.8em;
        margin-top: 4px;
    }

    i {
        margin: auto;
        text-align: center;
        color: white;
    }

    span {
        margin: auto;
        text-align: center;
        color: white;
    }

    .location-card__stat__content {
        display: flex;
        flex-direction: column;
        h4 {
            font-size: 23px;
            color: #666666;
        }
        h5 {
            font-size: 13px;
            color: #666666;
        }
    }
}

.card.location-card {
    @media (max-width: 535px) {
        transform: scale(0.8);
        margin-left: -20px;
        transform-origin: left;
        margin-top: -25px;
    }
    @media (max-width: 394px) {
        transform: scale(0.7);
        margin-left: -20px;
        transform-origin: left;
        margin-top: -30px;
    }
    @media (max-width: 321px) {
        transform: scale(0.6);
        margin-left: -20px;
        transform-origin: left;
        margin-top: -35px;
    }
}


.stat-card {
    border: 1px solid #f1f1f1;
    border-radius: 10px;
    padding: 8px 12px;
    -webkit-box-shadow: 0px 4px 0px 0px rgba(237, 237, 237, 1);
    -moz-box-shadow: 0px 4px 0px 0px rgba(237, 237, 237, 1);
    box-shadow: 0px 4px 0px 0px rgba(237, 237, 237, 1);

    .card-body {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        .stat-card-icon {
            background-color: #00b24c;
            border-radius: 50%;
            width: 56px;
            height: 56px;
            display: flex;
            margin-right: 10px;
            justify-content: center;
            align-items: center;
            color: whitesmoke;

            &.green {
                background-color: #00b24c;
            }

            &.yellow {
                background-color: #d3c833;
            }

            &.red {
                background-color: #d83f1e;
            }

            &.blue {
                background-color: #11599c;
            }

            &.turquoise {
                background-color: #00868B;
            }
            
            &.no-color {
                background-color: transparent;
            }

            @media only screen and (max-width: 640px) {
                width: 40px;
                height: 40px;
                font-size: 0.85rem;
            }
        }

        i {
            margin: auto;
            text-align: center;
            color: white;
            font-size: 2em;
            @media only screen and (max-width: 640px) {
                font-size: 1.5rem;
            }
        }

        span {
            margin: auto;
            text-align: center;
            color: white;
            font-size: 2.5em;
            @media only screen and (max-width: 640px) {
                font-size: 1.15rem;
            }
        }

        .stat-card-content {
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            h2 {
                font-size: 20px;
                color: #666666;
            }
            h4 {
                font-size: 12px;
                color: #666666;
            }
        }
    }
}

//Header1
.header-container {
    display: flex;
    height: 50px;
    padding: 0;
    @media (max-width: 1025px) {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    @media (max-width: 450px) {
        margin-top: 38px;
    }
}

.header-text {
    margin-top: 6px;
    margin-left: 10px;
    color: #1d78db;
}

.header-bar {
    width: 5px;
    background: #1d78db;
}

.header2 {
    color: #a8a8a8;
}
.header4 {
    color: #1d78db;
    cursor: pointer;
}
.header6 {
    color: #a8a8a8;
}

//Buttons
.btn-outline-primary {
    background-color: #eef8ff;
    border-color: #1d78db;
    color: #1d78db;
}

//Inputs
.form-control-lg {
    border-color: #1d78db;
    width: 310px;
    @media (max-width: 441px) {
        width: 100%;
    }
}

//Breadcrumb
.breadcrumb {
    background-color: inherit;
    border-bottom: 0;
    margin-bottom: 0px;
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    @media (max-width: 441px) {
        margin-top: 10px;
    }
}

.progress-row {
    display: flex;
}

.progress-value {
    color: black;
}
.progress-group {
    margin-bottom: 0px !important;
}

.progress-xs {
    width: 88%;
}

.progress-group.money {
    margin-bottom: 0px !important;
    margin-top: 10px;
}

//Lable Value

.lable-value-list {
    padding-left: 0;
    @media (max-width: 767px) {
        margin-left: 10px;
    }
}

.lv-container,
.lv-container-1 {
    padding: 10px;
    border: 1px solid #f1f1f1;
    border-radius: 10px;
    display: flex;
    margin-bottom: 13px;
}

.lv-container-1.row {
    @media (max-width: 1200px) {
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
    }
    @media (max-width: 500px) {
        min-width: 230px;
    }
}

.lv-widget-1 {
    // border-right: 2px dotted hsl(204, 9%, 90%);
    padding-right: 20px;
    @media (max-width: 1200px) {
        border-right: 0px;
        padding-right: 0px;
        padding-bottom: 10px;
        // border-bottom: 2px dotted hsl(204, 9%, 90%);
    }
}

.lv-widget {
    border-right: 2px dotted hsl(204, 9%, 90%);
    padding-right: 20px;
    @media (max-width: 500px) {
        border-right: 0px;
        padding-right: 0px;
    }
}
.lv {
    margin-top: 6px;
}

.money {
    text-align: center;
    min-width: 116px;
}
.money.col-md-10 {
    margin-left: -15px;
}

.money-group {
    flex-wrap: initial;
    width: 138px;
    margin-left: auto;
    margin-right: auto !important;
    @media (max-width: 1200px) {
        margin-top: 10px;
    }
}

.lv-lable {
    color: #a8a8a8;
}

.lv-value {
    color: #1d78db;
    margin-left: 8px;
}

.money-arrow {
    margin-top: 7px;
}

.money-arrow-progress {
    margin-top: 7px;
    margin-left: -2vw;
}

.pw-container {
    padding: 10px;
    border: 1px solid #f1f1f1;
    border-radius: 15px;
    margin-bottom: 25px;
}

.flex {
    display: flex;
}

.form-control.date-picker {
    font-size: 17px;
    height: 43px;
    border: none;
    border-left: 1px solid #1d78db;
}

.date-range-picker {
    border: 1px solid #1d78db;
    border-radius: 5px;
    width: 280px;
    z-index: 1;
    height: 46px;
}

.single-date-picker {
    border: 1px solid #1d78db;
    border-radius: 5px;
    z-index: 1;
    height: 46px;
}

.date-picker.input-group-text {
    background-color: white;
    color: #1d78db;
}

.in-range:not(.end-date) {
    background-color: hsl(206, 71%, 86%) !important;
}

//collapsible-list
.item-list {
    list-style-type: none;
    color: #1d78db;
    padding-left: 10px;
}

.collapsible-bar.selected {
    width: 4px;
    background: #1d78db;
}

.collapsible-bar {
    width: 4px;
}

.collapsible-container {
    display: flex;
    padding: 0;
}

.collapsible-lable {
    margin-top: 2px;
    margin-left: 10px;
    cursor: pointer;
}

.child-list {
    display: none;
    padding-left: 30px;
}

.child-list.visible {
    display: block;
    padding-left: 30px;
}

.collapsible-child {
    font-size: 16px;
    list-style-type: none;
    color: #a8a8a8;
    cursor: pointer;
}

.collapsible-child.selected {
    font-weight: bold;
}

//Lable Value List
.lable-value {
    display: flex;
}

.lable-value-item {
    color: #a8a8a8;
    font-size: 18px;
    @media (max-width: 769px) {
        font-size: 14px;
    }
    @media (max-width: 465px) {
        font-size: 13px;
    }
}

.lable-value-value {
    color: #1d78db;
    margin-left: 10px;
    font-size: 17px;
    @media (max-width: 769px) {
        font-size: 14px;
    }
    @media (max-width: 465px) {
        font-size: 13px;
    }
}

//Chart
.lv-widget-chart {
    text-align: center;
}

.h2-number {
    font-size: 35px;
    color: #1d78db;
    @media (max-width: 400px) {
        font-size: 20px;
    }
}

.h3-text {
    font-size: 25px;
    color: #a8a8a8;
    @media (max-width: 400px) {
        font-size: 11px;
    }
}

.chart-row.row {
    @media (max-width: 500px) {
        margin-left: 2vw;
        margin-right: -10vw !important;
    }
}

.chart-container {
    position: relative;
    width: 100%;
    height: 80px;
    @media (max-width: 400px) {
        height: 35px;
    }
}

.chart.chartjs-render-monitor {
    width: 100% !important;
}

.chart2 {
    @media (max-width: 1200px) {
        margin-top: 15px;
    }
}

//Product history table

.product-history.card:not(.list) {
    @media (max-width: 450px) {
        display: none;
    }
}

.product-history-th {
    border-top: none !important;
    border-bottom: 6px solid #f1f1f1 !important;
    color: #1d78db;
}

.product-history-tr {
    border-bottom: 2px solid #f1f1f1 !important;
    color: #878787;
}

.page-item:not(.disabled) > .page-link {
    color: #1d78db;
}

.page-item.active > .page-link {
    background-color: #c1e4fd;
}

.product-history-card-body {
    padding: 0;
}

.empty-row {
    text-align: center;
}

.pagination.justify-content-end {
    margin-right: 15px;
}

.nav-pagination {
    display: inline-block;
}

.position-relative.row.form-group {
    padding-left: 15px;
    padding-right: 20px;
    margin-top: 8px;
    @media (max-width: 767px) {
        flex-wrap: inherit;
    }
    @media (max-width: 500px) {
        flex-wrap: wrap;
        .pagination-div {
            margin-top: 15px;
            display: flex;
        }
    }
    @media (max-width: 425px) {
        flex-wrap: wrap;
        .pagination.justify-content-end li:first-child,
        .pagination.justify-content-end li:last-child {
            display: none;
        }
    }
}

.no-margins {
    padding-left: 0;
    padding-right: 0;
}

.history.header-container {
    @media (max-width: 765px) {
        float: left !important;
    }
}

.row .header-container {
    // margin-top: 25px !important;
    @media (max-width: 767px) {
        margin-top: 20px !important;
        margin-bottom: 15px !important;
    }
    @media (max-width: 450px) {
        margin-top: 8px !important;
    }
}

.product-history.form-control {
    width: 65px;
}

.loader-container {
  position: relative;
  
  > .loading {
    position: absolute;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .25);
    display: flex;
    justify-content: center;
    align-items: center;
    > .sk-fading-circle {
      transform: translateY(-40px);
    }
  }
}


//Top bar
.topBar {
    display: flex;
    width: 98%;
    @media (max-width: 1100px) {
        display: inherit;
        .header-right {
            margin-left: -20px;
            margin-bottom: -20px;
            margin-top: 15px;
        }
    }
    @media (max-width: 500px) {
        display: flex;
        flex-direction: column-reverse;
        .header-right {
            margin-left: -20px;
            margin-bottom: -20px;
        }
    }
}

.header-right {
    margin-left: auto;
    display: flex;
    > * {
        margin-left: 20px;
    }
    @media (max-width: 550px) {
        display: block;
    }
}
.map {
    height: 44px;
    margin-right: 20px;
    border: 1px solid #1d78db;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
    border-color: #1d78db;
}

.search {
    height: 44px;
}

.select {
    width: 11.8rem;
    margin-bottom: 1rem !important;
    margin-right: 20px;
    background-color: white;
    color: #1d78db;
    height: 45px;
}

//Body container
.body-container {
    margin-top: 40px;
    @media (max-width: 500px) {
        margin-top: 0px;
    }
}

//Map
.card-body.map {
    border: 1px solid #f1f1f1;
    border-radius: 10px;
    box-shadow: 0px 4px 0px 0px #ededed;
}

.maps.card {
    width: 97%;
    border: 1px solid #f1f1f1;
    border-radius: 10px;
    box-shadow: 0px 4px 0px 0px #ededed;
}

//Location page
.location-body-container {
    @media (min-width: 430px) {
        margin-top: 20px;
    }
}
.location-body-container .row {
    margin-right: 6px;
    @media (max-width: 500px) {
        margin-right: 0px;
    }
}

.location-body-container .header-container {
    margin-left: 20px;
}

.location-body-header-right {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    & > * {
        margin-left: 15px;
    }
    @media (max-width: 1200px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;
        margin-left: 0px;
        margin-top: 10px;
        justify-content: space-between;
        & > * {
            margin-left: 0px;
        } 
    }
}

.location-progress-bar {
    width: 80%;
}

.location-progress-bar > * {
    width: 250px;
}

.div-progress-bar {
    width: 290px;
    padding: 15px;
    margin-right: 2vw;
    @media (max-width: 991.98px) {
        width: 221px;
    }
}

.location-stats-col {
    margin-top: 22px;
}

.location-stats-row {
    margin-top: 22px;
    @media (max-width: 450px) {
        margin-left: 5px;
    }
}

.row .header-container {
    margin-top: 10px;
    margin-bottom: 10px;
}

a {
    color: #1d78db;
}

//Products events
.history.header-container {
    float: right;
}

.bg-danger {
    background-color: #d83f1e !important;
}

.event-button {
    margin-bottom: 20px;
    margin-left: 24px;
    height: 39px;
    width: 9vw;
    min-width: 60px;
    @media (max-width: 465px) {
        font-size: 10px;
    }
}

.event-button.btn-secondary {
    background-color: #e5e5e5;
}

.selected-view-button {
    font-size: 1.09375rem;
    @media (max-width: 465px) {
        font-size: 14px;
    }
}

.event-div {
    margin-top: 20px;
    margin-left: 10px;
    @media (max-width: 425px) {
        margin-left: 0px;
    }
}

.event-div .row {
    @media (max-width: 760px) {
        flex-direction: row !important;
    }
}

.product-image {
    margin-top: 20px;
    margin-bottom: 20px;
    @media (max-width: 465px) {
        margin-top: 5px;
        margin-bottom: 5px;
        min-width: 239px;
        margin-left: 8px;
    }
}

.event-lable-div {
    margin-top: 20px;
}

.modal-title {
    color: #1d78db;
}

.modal-button-1 {
    background-color: #1d78db;
}

.header-container .header-text {
    @media (max-width: 450px) {
        margin-top: 7px;
        font-size: 27px;
    }
}

@media (min-width: 576px) {
    html:not([dir='rtl']) .sidebar-sm-show.sidebar-fixed .main,
    html:not([dir='rtl']) .sidebar-sm-show.sidebar-fixed .app-footer,
    html:not([dir='rtl']) .sidebar-show.sidebar-fixed .main,
    html:not([dir='rtl']) .sidebar-show.sidebar-fixed .app-footer {
        margin-left: 0px !important;
        position: absolute;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        content: '';
        -webkit-animation: opacity 0.25s;
        animation: opacity 0.25s;
    }
    html:not([dir='rtl']) .sidebar-sm-show.sidebar-fixed .main::before,
    html:not([dir='rtl']) .sidebar-show.sidebar-fixed .main::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        background-image: initial;
        background-position-x: initial;
        background-position-y: initial;
        background-size: initial;
        background-attachment: initial;
        background-origin: initial;
        background-clip: initial;
        background-color: rgba(0, 0, 0, 0.7);
        -webkit-animation: opacity 0.25s;
        animation: opacity 0.25s;
    }
}

.sidebar-show .main::before,
.aside-menu-show .main::before {
    @media (max-width: 575.98px) {
        z-index: 2;
    }
}

.buttons {
    @media (max-width: 500px) {
        margin-bottom: 0px;
    }
}

.item-title {
    margin-left: 9px;
    text-decoration: underline;
    font-size: 15px;
}

.item-lv {
    margin-top: 4px;
}

.product-history.card.list {
    width: 92%;
    @media (min-width: 470px) {
        display: none;
    }
}

.history-list-item {
    margin-top: 4px;
}

.pagination-div-1 {
    @media (min-width: 470px) {
        display: none;
    }
}

.mobile-select-1 {
    color: #1d78db;
    margin-bottom: 3px;
    font-size: 15px;
    background-color: white;
}

.mobile-select-2 {
    color: #1d78db;
    margin-bottom: 3px;
    font-size: 15px;
    background-color: white;
}
.mobile-select-3 {
    color: #1d78db;
    width: 98%;
    margin-bottom: 3px;
    font-size: 15px;
    background-color: white;
    @media (min-width: 450px) {
        display: none;
    }
}

.mobile-select {
    @media (min-width: 450px) {
        display: none;
    }
}

.collapsible-menu {
    @media (max-width: 450px) {
        display: none;
    }
}

.lv-container-1.row {
    @media (max-width: 450px) {
        display: none;
    }
}

.lable-value-desktop {
    @media (max-width: 450px) {
        display: none;
    }
}
.lable-value-mobile {
    @media (min-width: 450px) {
        display: none;
    }
    margin-top: 11px;
}

.custom-table-mobile-no-data {
    @media (min-width: 450px) {
        display: none;
    }
}

.product-reverse {
    @media (max-width: 450px) {
        flex-direction: column-reverse;
    }
}

.ril-inner.ril__inner {
    z-index: 4;
    top: 100px;
}

.ril-toolbar.ril__toolbar {
    z-index: 4;
    top: 50px;
}

.error.event-button {
    margin-bottom: 0px;
    margin-top: 17px;
}

.card.event {
    margin-top: 20px;
}

select.mobile-select-1,
select.mobile-select-2,
select.mobile-select-3,
.select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
}
select + i.fa-icon {
    @media (min-width: 430px) {
        display: none;
    }
    @media (max-width: 430px) {
        float: right;
        margin-top: -31px;
        margin-right: 11px;
        pointer-events: none;
        background-color: #fff;
        padding-right: 5px;
        color: #1d78db;
    }
}

select + i.fa-icon-2 {
    float: right;
    margin-top: -31px;
    margin-right: 11px;
    pointer-events: none;
    background-color: #fff;
    padding-right: 5px;
    color: #1d78db;
}

.fa-icon.fa.fa-chevron-down {
    float: right;
    margin-top: -30px;
    margin-right: 8px;
    pointer-events: none;
    background-color: #fff;
    padding-right: 5px;
    color: #1d78db;
}
.fa-icon-2.fa.fa-chevron-down {
    float: right;
    margin-top: -44px;
    margin-right: 8px;
    pointer-events: none;
    background-color: #fff;
    padding-right: 5px;
    color: #1d78db;
}

.select.form-group {
    height: 44px;
}

.select-1.form-group {
    width: 41%;
    margin-left: 20px;
}

.select-2.form-group {
    width: 41%;
    margin-left: 20px;
}
.select-3.form-group {
    margin-left: 20px;
}

.animated.fadeIn.pt-1.small {
    padding: 0 !important;
    > .sk-fading-circle {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin: 0;
        > .sk-circle {
            margin: 0;
        }
    }
}

.login-tag {
  display: inline-block;
  vertical-align: middle;
  > * {
    line-height: 1.2em;
  }

  > .account {
    font-size: .77em;
  }

}

.clickable-quicksight-attribution {
    display: none !important;
}

.last-refreshed {
    padding-left: 1%;
    padding-top: 10px;
}

.product-category-list-item {
    padding: 10px 0px;
    border-bottom: #DDDDDD solid 1px;
    & > a {
        color: inherit;
        font-size: 1rem;
        &:hover {
            text-decoration: none;
        }
        display: flex;
        @media only screen and (max-width: 640px) {
            flex-direction: column;
        }
    }

    .product-name {
        font-size: 1.25rem;
        flex: 1;
        text-transform: capitalize;
    }
    .stats {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .stat {
        padding: 4px 24px;
        margin: 0px 10px 0px 0px;
        min-width: 120px;
        text-align: center;
        color: white;
        border-radius: 0.25rem;
        background-color: #777777;
        @media only screen and (max-width: 640px) {
            padding: 4px 6px;
            font-size: 1rem;
            min-width: 0px;
        }
    }
    .red { background-color: #d83f1e; }
    .yellow { background-color: #d3c833; }
    .green { background-color: #00b24c; }

    .chevron {flex: 0;}
    .last-updated { font-size: 14px; color: #777777}
}

.dashboard {
    margin-top: 20px;
    padding-bottom: 15px !important;

    &-error-msg {
        color: red;
        margin-top: 5px;
    }

    &-no-data {
        color: black;
        display: flex;
        justify-content: center;
        margin-top: 15px;
        font-size: 18px;
    }
}


.last-updated {
    margin-top: 15px;
}

.highlight {
    background-color: #DAE0E6;
}

.product-events-configs-dropdown {
    width: 280px;
}

.qa-statistics {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.tracker-info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    color: honeydew;
    padding-top: 10px;

    &.item {
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        color: #666666;
    }

    &.is-perfect-facing {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-color: #A9A9A9;
    }

    &.product-count {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-color: #A9A9A9;
    }

}

.dropdown-item > i.right {
    margin-left: 10px;
    margin-right: -10px;
}

.plus-sign-images {
    position:absolute;
    margin-top: 20px;
    margin-bottom: 20px;
    @media (max-width: 465px) {
        margin-top: 5px;
        margin-bottom: 5px;
        min-width: 25px;
        margin-left: 8px;
    }
    top: 0px;
    left: 20px;
    background-color: rgba(200, 200, 200, 0.5);
    width: 25px;
    height: 25px;
}

.rw-ddl-style {
    box-sizing: border-box;
    background-clip: border-box;
    border: #ccc 1px solid;
    font-size: 16px;
    font-family: inherit;
    outline: none;
    position: relative;
    height: 2.5em
}

.facing-dims {
    background-color: 'red';

    .times::after {
        display: inline-block;
        padding: 0px 5px;
        content: x;
    }

    .facing-count{
        text-align: right;
        width: 3.5em;
    }

}

.voxel-color-legend {
    :not(:first-child) {
        display: flex;
        justify-content: space-between;
        font-size: 0.75rem;
    }

    .square {
        height: 12px;
        width: 12px;
        border-radius: 25%;
    }
}

.qa-event-history-list-item {

    .item {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 4px 0px;
    }

    a {
        text-decoration: none;
    }

    .date {
        text-align: center;
        width: 44px;
    }

    * {
        margin-right: 0.5rem;
    }

    .time {
        flex: 1
    }

    .event-type {
        padding: 2px 8px;
        color: white;
        border-radius: 0.25rem;
        min-width: 60px;
        text-align: center;
    }

    .error {
        background-color: gray;
    }

    .out {
        background-color: #d83f1e;
    }

    .low {
        background-color: #d3c833;
    }

    .high {
        background-color: #00b24c;
    }

    &.selected {
        background-color: #c1e4fd;
    }
}

.qa-content {
    height: 100%;
    padding-left: 24px;

    &.border-left {
        border-left: 1px grey solid;

        @media only screen and (max-width: 640px) {
        border-left: none !important;
        padding-left: 0px;
        }
    }
}

.qa-event-history-list {
    overflow-y: auto;
    height: 510px;

    &>div {
        border-bottom: 1px solid grey;
    }

    :last-child {
        border-bottom: none;
    }

    @media only screen and (max-width: 640px) {
        display: none;
    }

    @media only screen and (min-width: 1440px){
        height: 530px;
    }

    @media only screen and (min-width: 900px) and (max-width: 1440px) {
        height: 250px;
    }

    @media only screen and (min-width: 800px) and (max-width: 900px) {
        height: 200px;
    }

    @media only screen and (min-width: 750px) and (max-width: 800px) {
        height: 200px;
    }
}

.qa-event-history-dropdown {
    padding: 10px;

    @media only screen and (min-width: 640px) {
        display: none;
    }
}

.qa-event-history-dropdown-dd {
    height: 530px;
    overflow-y: auto;
}

.qa-event-history-img {
    height: 100%;
    text-align: center;

    img {
        height: auto;
        width: 100%;

        @media only screen and (min-width: 1440px){
        width: 510px;
        }

        @media only screen and (min-width: 900px) and (max-width: 1000px) {
        width: 85%;
        }

        @media only screen and (min-width: 800px) and (max-width: 900px) {
        width: 90%;
        }

        @media only screen and (min-width: 750px) and (max-width: 800px) {
        width: 95%;
        }

    }
}

.dashboard-triggers-modal{
    .modal-header {
        font-size: 1.3rem;
    }

    .triggers-modal-table {
    
        .table-header {
            font-size: 1.1rem;
        }
    
        .row-trigger-name {
            font-weight: bold;
            font-size: 1.0rem;
        }
    
        .row-trigger-value {
            font-size: 0.8rem;
            font-size: 1.0rem;
        }
    }
}
